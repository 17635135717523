import { Component, Input, inject, OnInit } from "@angular/core";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { Source } from "src/app/models/shared";
import { SourcesService } from "src/app/pages/sources/sources.service";

@Component({
    selector: "app-live-event-sources-status-column",
    templateUrl: "./live-event-sources-status-column.component.html",
    styleUrls: ["./live-event-sources-status-column.component.scss"]
})
export class LiveEventSourcesStatusColumnComponent implements OnInit {
    @Input() sources: Source[] = [];
    private sourceService = inject(SourcesService);
    isMouseInsidePopover = false;

    ngOnInit() {
        this.sourceService.refreshSources(false);
    }
    onMouseLeave(popover: NgbPopover) {
        setTimeout(() => !this.isMouseInsidePopover && popover.close(), 300);
    }
}
